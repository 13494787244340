<template>
  <div>
    <p></p>
    <div class="build_place">
      <span class="title_run">{{$t('wizards.SystemLogAnalysis.CheckingHardwareMatches')}}</span>
      <button v-if="false" @click="run" class="bnt_run">{{ $t('reports.reportsoverview.build') }}</button>
      <button v-if="is_super" @click="run_rebuild" class="bnt_rebuild">{{ $t('wizards.SystemLogAnalysis.rebuild') }}</button>
    </div>

    <p></p>

    <div class="filters dotted-border"><span>🔍 </span>
      <input
          type="text"
          id="sla_label_id_u11"
          v-model="filtersEl.login"
          :placeholder="$t('wizards.SystemLogAnalysis.filter_by_user')"
      >
      <input
          type="text"
          id="sla_label_id_u12"
          v-model="filtersEl.bill_id"
          :placeholder="$t('wizards.SystemLogAnalysis.filter_by_account_number')"
      >
      <label class="other_soft" id="sla_label_id_u13">
        <input v-model="filtersEl.hide_blocked_bill" type="checkbox" id="sla_label_id_u14">
        <span>{{$t('wizards.SystemLogAnalysis.hide_blocked_bill')}}</span>
      </label>

      <label class="last30">
        <input v-model="last30" type="checkbox">
        <span>{{$t('wizards.SystemLogAnalysis.last30')}}</span>
      </label>
    </div>

    <div v-for="(item, key) in dataForParts" :key="key">
      <el-table
          :data="item.user_bill_data"
          :row-class-name="rowClass"
          class="tbl">
        <el-table-column
            v-for="column in columnsTable"
            :key="column.field"
            :prop="column.field"
            :label="column.label"
            :min-width="column.minwidth">
          <template v-if="['bill_path', 'third_party_apps', 'id'].includes(column.field)" #default="props">
            <span v-if="column.field=='bill_path'">
              <span v-for="(item, key) in props.row[column.field]" :key="key">
                <span class="bill_path_label" :class="{bill_path_label_end: key+1==props.row[column.field].length}">{{item}}</span>
                <span v-if="key+1<props.row[column.field].length" class="bill_path_separator">></span>
              </span>
            </span>
            <span v-if="column.field=='third_party_apps'">
            <details>
              <summary></summary>
              <p><b>Приложение:</b>
                <span v-if="props.row['app_type']=='cashier'">👤</span>
                <span v-if="props.row['app_type']=='monitor'">🖥</span>
              </p>
              <p><b>ОС:</b> {{props.row['os']}}</p>
              <p><b>uid:</b> {{props.row['uid']}}</p>
              <p><b>Серийный номер системного тома:</b> {{props.row['system_volume_serial']}}</p>
              <p><b>remote_ip:</b> {{props.row['remote_ip']}}</p>
              <p><b>Сторонние приложения:</b></p>
              <table class="apps">
                <thead>
                  <tr>
                    <th>app</th>
                    <th>installed</th>
                    <th>active</th>
                    <th>date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(itemflg, keyflg) in props.row['jdata'].third_party_apps" :key="keyflg">
                    <td>{{keyflg}}</td>
                    <td><input :id="'installed'+'p'+key+'r'+props.$index+'d'+keyflg" type="checkbox" :checked="itemflg.is_installed" disabled></td>
                    <td><input :id="'active'+'p'+key+'r'+props.$index+'d'+keyflg" type="checkbox" :checked="itemflg.is_active" disabled></td>
                    <td>{{itemflg.installation_date}}</td>
                  </tr>
                </tbody>
              </table>
              </details>
            </span>
            <span v-if="column.field=='id'">
              <button @click="delRowSi(props.row[column.field], props.row['path'])" :id="props.row[column.field]" type="button" class="btn btn-danger p-1 delete-button" title="">
                <div class="icon-size"><font-awesome-icon icon="trash-alt" /></div>
              </button>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <p></p>
    </div>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, provide, reactive, ref, watchEffect, watch} from "vue";
import WizardsService from "../../services/wizards.service";

const $t = inject('$t')

const set_result = inject('set_result')

const is_super = inject('is_super')

const currentUser = inject('currentUser')
const tz_list = inject('tz_list')
const dataformatEl=inject('dataformatEl')
const langEl=inject('langEl')
const is_run = ref(false)
const setIsLoading = inject('setIsLoading')
const runProcess = inject('runProcess')
const date_to_format = inject('date_to_format')
const deepClone = inject('deepClone')

const data_type = inject('data_type')

const last30 = ref(true)

let date_start=ref(new Date());
let cday=new Date();
let date_end=ref(new Date(cday.setDate(cday.getDate()+1)))

date_start.value=new Date(date_start.value.toDateString());
date_end.value=new Date(date_end.value.toDateString());

function disabledDate(time)
{
  let res=false

  if (time.getTime() > Date.now()) {
    res=true
  }

  let cdayd=new Date();
  let date_from=new Date(cdayd.setDate(cdayd.getDate()-10)).valueOf()
  if (time.getTime() < date_from) {
    res=true
  }

  return res
}

const currentUserTZ = computed( ()=>currentUser.value.timezone)

const filterData = reactive({
  ds: date_start,
  de: date_end,
  tz: 'UTC+0'
})

const filtersEl = ref({'login': '', bill_id: '', hide_blocked_bill: true})
const filtersOptionsEl = ref({})

function fieldsTable()
{
  let fld = []

  fld.push({label: $t('wizards.SystemLogAnalysis.last_active'), field: 'last_active', minwidth: '20'})
  fld.push({label: $t('wizards.SystemLogAnalysis.user'), field: 'login', minwidth: '22'})
  fld.push({label: $t('wizards.SystemLogAnalysis.bill_path'), field: 'bill_path'})
  fld.push({label: $t('wizards.SystemLogAnalysis.updated'), field: 'updated', minwidth: '22'})
  fld.push({label: $t('wizards.SystemLogAnalysis.mac'), field: 'mac', minwidth: '24'})
  fld.push({label: $t('wizards.SystemLogAnalysis.disk_serial'), field: 'disk_serial', minwidth: '30'})
  fld.push({label: $t('wizards.SystemLogAnalysis.third_party_apps'), field: 'third_party_apps'})

  if(is_super.value==true) {
    fld.push({label: '', field: 'id', minwidth: '6'})
  }

  return fld
}
const columnsTable = computed(fieldsTable)

function updatefilterData(data)
{
  if(data.ds!=undefined) {
    filterData.ds = data.ds
  }

  if(data.de!=undefined) {
    filterData.de = data.de
  }

  if(data.tz!=undefined) {
    filterData.tz = data.tz
  }
}

const data = ref([])
const dataForParts = ref([])

function filterTableDataFn()
{
  let filterData = []
  let is_filter_ok = false

  if(data.value['unic_res']==undefined) {
    return
  }

  let main_data = JSON.parse(JSON.stringify(data.value['unic_res']));

  for (let currow in main_data) {
    let cur = main_data[currow]

    is_filter_ok = false
    let is_filter_ok_tmp = true
    let is_del_all = false
    for (let currowud in cur.user_bill_data) {
      let curud = cur.user_bill_data[currowud]

      curud['path'] = {fl: currow, sl: currowud}

      //Номер евента
      if (filtersEl.value.login != '') {
        is_filter_ok_tmp = false
        if (curud.login.includes(filtersEl.value.login)==true) {
          is_filter_ok_tmp = true
        }
      }

      if (filtersEl.value.bill_id != '') {
        is_filter_ok_tmp = false
        if (filtersEl.value.bill_id==curud.bill_id) {
          is_filter_ok_tmp = true
        }
      }

      if (is_filter_ok_tmp == true) {
        is_filter_ok = true
      }

      if(filtersEl.value.hide_blocked_bill==true && curud.bill_status!='active') {
        if (currowud==0) {
          is_del_all = true;
        }

        // delete cur.user_bill_data[currowud];
      }
    }

    if (is_filter_ok == true) {
      // if(Object.keys(cur.user_bill_data).length>0 && is_del_all != true) {
      if(is_del_all != true) {
        filterData.push(cur);
      }
    }
  }

  dataForParts.value=filterData

  return filterData
}
watchEffect(filterTableDataFn)

function chageLast30_fn()
{
  run()
}
watch(last30, chageLast30_fn)

function run()
{
  setIsLoading(true)

  data.value=[]
  WizardsService.systemLogAnalysis({last30: last30.value}).then(
    (response) => {
      data.value=response.data

      filterTableDataFn()

      is_run.value=true
      setIsLoading(false)
      runProcess.value=true
    },
    (error) => {
      set_result(error.response.status, error)
      setIsLoading(false)
      runProcess.value=true
    }
  )
}

function run_rebuild()
{
  setIsLoading(true)

  data.value=[]
  WizardsService.systemLogAnalysisUpdate().then(
      () => {
        run();
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
        runProcess.value=true
      }
  )
}

function delRowSi(id, path)
{
  if(is_super.value!=true) {
    return
  }

  setIsLoading(true)
  WizardsService.systemLogAnalysisDel({id: id}).then(
      () => {
        let fl=path['fl'];
        let sl=path['sl'];

        data.value['unic_res'][fl]['user_bill_data'][sl]['delete']=true;

        setIsLoading(false)
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
      }
  )
}

function rowClass({ row, rowIndex})
{
  let res = '';

  if(row.bill_status!='active') {
    res = 'blocked';
  }

  if(row.delete==true) {
    res = 'delete';
  }

  return res
}

function parseLogin(login)
{
  try {
    let jsn=JSON.parse(login)

    let res=''

    for(let key in jsn) {
      let cur = jsn[key]

      res = res + '<div class="login_block">' + cur + '</div>'
    }

    return res;
  } catch (e) {
    return login;
  }
}

function mounted_fn()
{
  run()
}
onMounted(mounted_fn)
</script>

<style scoped>
.title_run {
  font-size: 15pt;
  font-weight: bold;
  font-family: "Times New Roman";
}

.build_place {
  position: relative;
  left: 20px;
  width: 97%;
  border: #1e7e34 1px solid;
}

.bnt_run {
  margin-left: 10px;
  font-size: 10pt;
}

.bnt_rebuild {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 10pt;
}

.filters {
  display: flex;
  flex-direction: row;
  gap: 5px;
  border: 2px;
}

.dotted-border {
  border: 2px groove #000;
  padding: 10px;
  margin: 20px;
  width: auto;
  height: auto;
}

.label {
  display: inline-block;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999;
  border-radius: 3px;
}

.selected {
  background-color: #468847;
}

details {
  font-size: small;
}

details summary {
  list-style: none;
  /* Убираем стандартный маркер */
  cursor: pointer;
}

details summary::before {
  content: '👁️';
  /* Стандартная иконка */
  display: inline-block;
  margin-right: 5px;
}

details[open] summary::before {
  content: '🔽';
  /* Иконка для открытого состояния */
}

/* Стилизация для summary, чтобы выглядело более естественно без стандартного маркера */
/*details summary::-webkit-details-marker {*/
/*  display: none;*/
/*}*/

details summary {
  padding: 0.5em 0;
  outline: none;
}

details p, details ul {
  margin: 0;
}

.styled-checkbox+label {
  cursor: default;
}

li {
  list-style-type: square;
}

.bill_path_label {
  display: inline-block;
  padding: 2px 4px;
  font-size: 11.844px;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999;
  border-radius: 3px;
}

.bill_path_label_end {
  background-color: #468847;
}

.bill_path_separator {
  font-size: 12pt;
  margin: 8px;
}

.other_soft {
  margin: 0 10px;
}

.other_soft input {
  cursor: pointer;
}

.other_soft span {
  margin-left: 10px;
  cursor: pointer;
}

.last30 {
  margin: 0 10px;
}

.last30 input {
  cursor: pointer;
}

.last30 span {
  margin-left: 10px;
  cursor: pointer;
}

.tbl {
  width: 100%;
  border-right: black 1px solid;
  border-bottom: black 1px solid;
}

.tbl :deep(th), .tbl :deep(td) {
  font-size: x-small;
  border: black 1px solid;
  color: black;
}

.tbl :deep(th) {
  font-weight: bold;
}

/* Стилизация для summary, чтобы выглядело более естественно без стандартного маркера */
/*details summary::-webkit-details-marker {*/
/*  display: none;*/
/*}*/

details summary {
  padding: 0.5em 0;
  outline: none;
}

details p,
details ul {
  margin: 0;
}

.styled-checkbox+label {
  cursor: default;
}

li {
  list-style-type: square;
}

.apps {
  width: 100%;
  border-collapse: collapse;
  font-size: x-small;
  margin: 0;
}

.apps :deep(td), .apps :deep(th) {
  padding: 2px 4px;
}

.apps :deep(th) {
  background: #dddddd;
}

.tbl :deep(.blocked) {
  background-color: #f2dede !important;
}

.tbl :deep(.delete) {
  background-color: #ccc !important;
}
</style>